import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as jobNavStyles from "./jobsNav.module.css"
import { Navbar, Nav } from "react-bootstrap"
import { SquareFill } from "react-bootstrap-icons"

// const JobsNav = splashPage => {

const JobsNav = ({ link }) => {
  // const splash = splashPage.splash

  // console.log(link)

  return (
    <>
      <div>
        <Navbar expand="lg" className="justify-content-center">
          <Nav fill className="mx-auto parent">
            <Link to="/senior-chefs">
              <div
                className={
                  link === "chefs"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                SENIOR CHEFS
              </div>
            </Link>
            <Link to="/front-of-house">
              <div
                className={
                  link === "front"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                FRONT OF HOUSE MANAGEMENT
              </div>
            </Link>
            <Link to="/sales-marketing-events">
              <div
                className={
                  link === "sales"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                SALES MARKETING EVENTS
              </div>
            </Link>
            <Link to="/operations">
              <div
                className={
                  link === "operations"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                {/* GENERAL MANAGEMENT & OPERATIONS */}
                General & operations managers
              </div>
            </Link>
            <Link to="/international">
              <div
                className={
                  link === "international"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                INTERNATIONAL
              </div>
            </Link>
          </Nav>
        </Navbar>
      </div>
    </>
  )
}

JobsNav.propTypes = {
  siteTitle: PropTypes.string,
}

JobsNav.defaultProps = {
  siteTitle: ``,
}

export default JobsNav
